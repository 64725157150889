<template>
  <b-modal
    id="dispatch-detail-modal"
    v-model="showForm"
    title="Appoint"
    ok-title="關閉"
    hide-header-close
    centered
    ok-only
    @ok="hide"
  >
    <div class="px-4">
      <b-row class="align-items-center">
        <b-col cols="4" class="mb-5">
          <span class="font-size-sm text-primary">
            Technician
          </span>
        </b-col>
        <b-col cols="8" class="mb-5">
          <span>
            {{ item.technician }}
          </span>
        </b-col>
        <b-col cols="4" class="mb-5">
          <span class="font-size-sm text-primary">
            Date
          </span>
        </b-col>
        <b-col cols="8" class="mb-5">
          <span>
            {{ $dayjs(item.schedule_date).format('YYYY-MM-DD HH:mm') }}
          </span>
        </b-col>
        <b-col cols="4" class="mb-5">
          <span class="font-size-sm text-primary">
            No.
          </span>
        </b-col>
        <b-col cols="8" class="mb-5">
          <span>
            {{ item.no }}
          </span>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4" class="mb-5">
          <span class="font-size-sm text-primary">
            Content
          </span>
        </b-col>
        <b-col cols="8" class="mb-5">
          <span>
            {{ item.content }}
          </span>
        </b-col>
        <b-col cols="4" class="mb-5">
          <span class="font-size-sm text-primary">
            Remark
          </span>
        </b-col>
        <b-col cols="8" class="mb-5">
          <span>
            {{ item.remark }}
          </span>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4" class="mb-5">
          <span class="font-size-sm text-primary">
            Attachment
          </span>
        </b-col>
        <b-col v-if="item.attachment!==undefined&&item.attachment.length>0" cols="8" class="mb-5">
          <b-link
            v-for="(v, index) in item.attachment"
            :key="index"
            href="#"
            class="mr-4"
            @click.prevent="onDownloadClick(v)"
          >
            Attachment {{ index+1 }}
          </b-link>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>
<script>
import { getAttachment } from '@/api/dispatch'
export default {
  name: 'DispatchDetailModal',
  data () {
    return {
      showForm: false,
      item: {}
    }
  },
  methods: {
    show (item) {
      this.item = item
      this.showForm = true
    },
    hide () {
      this.showForm = false
    },
    onDownloadClick (id) {
      getAttachment(id).then((res) => {
        const fileUrl = URL.createObjectURL(res.data)
        const fileLink = document.createElement('a')

        fileLink.href = fileUrl
        fileLink.setAttribute('target', '_blank')
        document.body.appendChild(fileLink)

        fileLink.click()
      })
    }
  }
}
</script>
