<template>
  <b-col cols="12" class="d-flex align-items-center mb-4">
    <b-form-file
      v-model="file"
      accept="*"
      class="report-file"
      :browse-text="'+'"
      placeholder="No file chosen"
      @change="onFilePicked"
    />
    <b-link v-if="file.length != 0" class="text-danger h2 btn-icon ml-auto" href="#" @click="onFileCancel">
      <font-awesome-icon
        :icon="['fas', 'times']"
        fixed-width
      />
    </b-link>
  </b-col>
</template>
<script>
export default {
  name: 'FileUpload',
  props: {
    index: {
      type: Number, default: 0
    }
  },
  data () {
    return { file: [] }
  },
  methods: {
    async onFilePicked (e) {
      e.preventDefault()
      const file = e.target.files[0]
      this.file = [file]

      this.$emit('file-picked', this.index, file)
    },
    onFileCancel () {
      this.file = []
      this.$emit('cancel', this.index)
    }
  }
}
</script>
