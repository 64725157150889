<template>
  <b-modal
    id="assign-service-modal"
    v-model="showForm"
    title="Appoint"
    cancel-title="CANCEL"
    ok-title="SAVE"
    hide-header-close
    centered
    @ok="submit"
  >
    <div class="px-4">
      <b-row>
        <b-col cols="12" class="mb-4">
          <b-form-group
            label="Technician"
            class="mb-4"
            :state="$v.form.$anyDirty ? !$v.form.technician.$anyError : null"
          >
            <b-form-input
              v-model="form.technician"
              placeholder="Technician"
            />
            <b-form-invalid-feedback :state="state($v.form.technician.required)">
              Technician is required
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12" class="mb-4">
          <b-form-group
            label="Appoint Date"
            class="mb-4"
            :state="$v.form.$anyDirty ? !$v.form.schedule_date.$anyError : null"
          >
            <b-form-datepicker v-model="form.schedule_date" class="mb-2" locale="en-US" placeholder="Appoint Date" />
            <b-form-invalid-feedback :state="state($v.form.schedule_date.required)">
              Appoint Date is required
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12" class="mb-4">
          <b-form-group
            label="Appoint Time"
            class="mb-4"
            :state="$v.form.$anyDirty ? !$v.form.schedule_time.$anyError : null"
          >
            <b-form-timepicker v-model="form.schedule_time" class="mb-2" locale="en-US" :hour12="false" placeholder="Appoint Time" />
            <b-form-invalid-feedback :state="state($v.form.schedule_time.required)">
              Appoint Time is required
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col v-if="form.id" cols="12" class="mb-4">
          <b-form-group
            label="Content"
            class="mb-4"
          >
            <b-form-textarea
              v-model="form.content"
            />
          </b-form-group>
        </b-col>
        <b-col v-if="form.id" cols="12" class="mb-4">
          <b-form-group
            label="Remark"
            class="mb-4"
          >
            <b-form-textarea
              v-model="form.remark"
            />
          </b-form-group>
        </b-col>
        <div v-for="index in 3" :key="index" class="w-100">
          <file-upload v-if="form.attachments[index-1]===undefined" :index="index-1" @file-picked="onFilePicked" @cancel="onFileCancel" />
          <b-col v-else cols="12" class="d-flex align-items-center mb-4">
            <b-link href="#" @click.prevent="onDownloadClick(form.attachments[index-1])">
              Attachment {{ index }}
            </b-link>
            <b-link class="text-danger h2 btn-icon ml-auto" href="#" @click="onAttachmentDelete(index-1)">
              <font-awesome-icon
                :icon="['fas', 'times']"
                fixed-width
              />
            </b-link>
          </b-col>
        </div>
      </b-row>
    </div>
  </b-modal>
</template>
<script>
import { required } from 'vuelidate/lib/validators'
import { createDispatch, updateDispatch, uploadFile, getAttachment } from '@/api/dispatch'
import FileUpload from '@/components/common/fileUpload'

export default {
  name: 'AssignServiceModal',
  components: {
    FileUpload
  },
  data () {
    return {
      showForm: false,
      form: {
        id: 0,
        technician: '',
        schedule_date: '',
        schedule_time: '',
        time: '',
        service_id: '',
        content: '',
        remark: '',
        attachments: []
      },
      files: [],
      loading: false
    }
  },
  validations () {
    return {
      form: {
        technician: { required },
        schedule_date: { required },
        schedule_time: { required }
      }
    }
  },
  methods: {
    show (serviceId, item = null) {
      if (item) {
        this.form = { ...item, attachments: item.attachment }
        const datetime = this.$dayjs(this.form.schedule_date)
        this.form.schedule_date = datetime.format('YYYY-MM-DD')
        this.form.schedule_time = datetime.format('HH:mm')
      } else {
        this.form = {
          id: 0,
          technician: '',
          schedule_date: '',
          schedule_time: '',
          service_id: serviceId,
          content: '',
          remark: '',
          attachments: []
        }
        this.files = []
      }
      this.showForm = true
    },
    hide () {
      this.showForm = false
    },
    state (vaild) {
      return this.$v.form.$anyDirty ? vaild : null
    },
    async onFilePicked (index, file) {
      this.files[index] = file
      // console.log(this.form.files)
    },
    onFileCancel (index) {
      this.files[index] = null
    },
    onAttachmentDelete (index) {
      this.form.attachments.splice(index, 1)
    },
    onDownloadClick (id) {
      getAttachment(id).then((res) => {
        const disposition = res.headers['content-disposition']
        const filename = disposition.substring(disposition.indexOf('filename=') + 9, disposition.length).replace(/"/g, '')
        const fileUrl = URL.createObjectURL(res.data)
        const fileLink = document.createElement('a')

        fileLink.href = fileUrl
        fileLink.download = filename

        fileLink.click()
      })
    },
    async submit (e) {
      if (this.loading) return
      this.loading = true
      e.preventDefault()
      this.$v.form.$touch()
      if (this.$v.form.$anyError) return

      // upload file
      const attachments = []
      for (const f of this.files.filter(a => a)) {
        await uploadFile(f).then(res => {
          attachments.push(res.data.data.attachment_id)
        })
      }

      const data = {
        id: this.form.id,
        technician: this.form.technician,
        schedule_date: this.form.schedule_date + ' ' + this.form.schedule_time,
        service_id: this.form.service_id,
        content: this.form.content,
        remark: this.form.remark,
        attachments: this.form.attachments
      }

      if (data.id) {
        for (const a of attachments) {
          data.attachments.push(a)
        }
        updateDispatch(data).then(() => {
          this.hide()
          this.loading = false
          this.$emit('update')
        })
      } else {
        data.attachments = attachments
        createDispatch(data).then(() => {
          this.hide()
          this.loading = false
          this.$emit('update')
        })
      }
    }
  }
}
</script>
