import apiService from '@/common/api.service'

export function getServiceList (status) {
  return apiService({
    url: '/api/service/list/status',
    method: 'get',
    params: { status }
  })
}

export function getServiceDetail (id) {
  return apiService({
    url: '/api/service',
    method: 'get',
    params: { id }
  })
}

export function serviceComplete (data) {
  return apiService({
    url: '/api/service/complete',
    method: 'patch',
    data
  })
}
export function getAttachment (id) {
  return apiService({
    url: '/api/service/attachment',
    responseType: 'blob',
    method: 'get',
    params: { id }
  })
}
