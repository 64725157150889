<template>
  <b-modal
    id="close-service-modal"
    v-model="showForm"
    title="Finished"
    cancel-title="CANCEL"
    ok-title="SAVE"
    hide-header-close
    centered
    size="lg"
    @ok="submit"
  >
    <div class="px-4">
      <b-row>
        <b-col cols="12" class="mb-4 table-base">
          <b-table
            ref="dispatchTable"
            v-model="selected"
            hover
            borderless
            selectable
            select-mode="single"
            :items="dispatchList"
            :fields="fields"
            @row-clicked="onRowSelected"
          >
            <template #head(no)>
              No.
            </template>
            <template #head(schedule_date)>
              Date
            </template>
            <template #head(technician)>
              Technician
            </template>
          </b-table>
        </b-col>
        <b-col cols="12" class="mb-4">
          <b-form-group
            label="Content"
            class="mb-4"
            :state="$v.form.$anyDirty ? !$v.form.dispatch_content.$anyError : null"
          >
            <b-form-textarea
              v-model="form.dispatch_content"
            />
            <b-form-invalid-feedback :state="state($v.form.dispatch_content.required)">
              Content is required
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12" class="mb-4">
          <b-form-group
            label="Remark"
            class="mb-4"
          >
            <b-form-textarea
              v-model="form.dispatch_remark"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>
<script>
import { required } from 'vuelidate/lib/validators'
import { serviceComplete } from '@/api/service'
export default {
  name: 'AssignServiceModal',

  data () {
    return {
      showForm: false,
      form: {
        id: 0,
        dispatch_id: 0,
        dispatch_content: '',
        dispatch_remark: ''
      },
      selected: [],
      dispatchList: []
    }
  },
  validations () {
    return {
      form: {
        dispatch_content: { required }
      }
    }
  },
  computed: {
    fields () {
      return ['no', 'schedule_date', 'technician']
    }
  },
  methods: {
    show (id, list) {
      this.form = {
        id: id,
        dispatch_id: 0,
        dispatch_content: '',
        dispatch_remark: ''
      }
      this.dispatchList = list
      this.showForm = true

      if (this.dispatchList.length > 0) {
        const selectItem = this.dispatchList[0]
        this.onRowSelected(selectItem)
        this.$nextTick(() => this.$refs.dispatchTable.selectRow(0))
      }
    },
    hide () {
      this.showForm = false
    },
    state (vaild) {
      return this.$v.form.$anyDirty ? vaild : null
    },
    onRowSelected (item) {
      this.form.dispatch_content = item.content
      this.form.dispatch_remark = item.remark
    },
    submit (e) {
      e.preventDefault()
      this.$v.form.$touch()
      if (this.$v.form.$anyError) return
      if (this.selected.length > 0) { this.form.dispatch_id = this.selected[0].id }
      serviceComplete(this.form).then(() => {
        this.hide()
        this.$emit('update', true)
      })
    }
  }
}
</script>
