import apiService from '@/common/api.service'

export function getDispatchList (id) {
  return apiService({
    url: '/api/dispatch/list',
    method: 'get',
    params: { id }
  })
}

export function createDispatch (data) {
  return apiService({
    url: '/api/dispatch',
    method: 'post',
    data
  })
}

export function updateDispatch (data) {
  return apiService({
    url: '/api/dispatch',
    method: 'patch',
    data
  })
}
export function uploadFile (file) {
  const data = new FormData()
  data.append('file', file)
  return apiService({
    url: '/api/dispatch/attachment',
    method: 'post',
    data
  })
}
export function getAttachment (id) {
  return apiService({
    url: '/api/dispatch/attachment',
    responseType: 'blob',
    method: 'get',
    params: { id }
  })
}

export function exportDispatch (id) {
  return apiService({
    url: '/api/dispatch/export',
    responseType: 'blob',
    method: 'get',
    params: { id }
  })
}
