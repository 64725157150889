<template>
  <b-row>
    <b-col cols="4">
      <b-tabs v-model="tabIndex" fill content-class="mt-3" class="tabs-base" @input="changeTabHandler">
        <b-tab title="Unassigned" />
        <b-tab title="Assigned" />
        <b-tab title="Complete" />
      </b-tabs>
      <div class="areawrap mt-6">
        <div class="list custom-scroll">
          <b-row
            v-for="item in serviceList"
            :key="item.id"
            :class="`list-item ${selected && selected.id===item.id?'selected':''}`"
            @click="onServiceClick(item.id)"
          >
            <b-col cols="12" class="mb-2 mt-4">
              {{ item.clinic_name }}
            </b-col>
            <b-col cols="12" class="mb-4">
              <b-row class="justify-content-between">
                <b-col cols="auto">
                  {{ $dayjs(item.applied_time).format('M月DD日  hh:mma') }}
                </b-col>
                <b-col cols="auto ml-auto">
                  <service-type-tags :types="[item.type]" />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-col>

    <b-col cols="8" class="pl-6">
      <div v-if="selected" class="areawrap">
        <div class="d-flex justify-content-between">
          <h2>{{ selected.clinic.clinic_name }}</h2>
          <b-button v-if="selected.status===1" variant="primary" class="btn-width" @click="onServiceCloseClick">
            Finished
          </b-button>
        </div>
        <b-row class="mt-4">
          <b-col cols="8" class=" d-flex flex-column justify-content-between">
            <div>
              <div class="d-flex justify-content-between align-items-end mb-2">
                <h3 class="mb-0 pl-4">
                  {{ selected.autoclave.name }}
                </h3>
                <service-type-tags :types="[selected.type]" />
              </div>
              <h4 class="caption pl-4">
                {{ model(selected.autoclave.model) }} {{ selected.autoclave.serial_number }}
                <br>
                Code Number： {{ selected.autoclave_message }}
              </h4>
              <hr>
              <b-row class="mt-4 pl-4">
                <b-col cols="12">
                  <b-form-group label="Title" label-class="text-primary mb-1">
                    {{ selected.title }}
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Content" label-class="text-primary mb-1">
                    {{ selected.content }}
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Attachment" label-class="text-primary mb-1">
                    <!-- {{ item.suggest_replacement }} -->
                    <b-row>
                      <b-col v-for="(item, index) in selected.attachment" :key="index" class="mr-4">
                        <b-link href="#" @click.prevent="onDownloadClick(item)">
                          Attachment {{ index+1 }}
                        </b-link>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <b-row class="my-4">
              <b-col>
                <b-button v-if="tabIndex > 0" :variant="showFunction === 0 ? 'primary' : 'outline-primary'" class="btn-width mr-4" @click="showFunction = 0">
                  派工列表
                </b-button>
                <b-button :variant="showFunction === 1 ? 'primary' : 'outline-primary'" class="btn-width" @click="showFunction = 1">
                  原廠支援
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="4">
            <b-row v-if="selected.status!==2" class="d-flex justify-content-end mb-4">
              <b-button
                variant="primary"
                class="btn-width mr-4"
                @click="$refs.assignServiceModal.show(selected.id)"
              >
                Appoint
              </b-button>
            </b-row>
            <b-row v-if="selected.status!==0" class="mt-4 pl-4">
              <b-col cols="12">
                <b-form-group label="Technician" label-class="text-primary mb-1">
                  <span v-if="dispatchItem"> {{ dispatchItem.technician }}</span>
                  <span v-else class="unassigned-text">
                    --
                  </span>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Expected Date" label-class="text-primary mb-1">
                  <span v-if="dispatchItem"> {{ dispatchItem.schedule_date }}</span>
                  <span v-else class="unassigned-text">
                    --
                  </span>
                </b-form-group>
              </b-col>
              <b-col v-if="selected.status===2" cols="12" class="mb-4">
                <b-button
                  variant="primary"
                  class="btn-width"
                  @click="$refs.dispatchDetailModal.show(dispatchItem)"
                >
                  Appoint Detail
                </b-button>
              </b-col>
            </b-row>
            <div class="clinic-info">
              <b-row>
                <b-col cols="12">
                  <b-form-group label="Contact Person" label-class="text-primary mb-1">
                    {{ selected.clinic.contact_name }}
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Phone" label-class="text-primary mb-1">
                    {{ selected.clinic.clinic_telphone }}
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Address" label-class="text-primary mb-1">
                    {{ selected.clinic.clinic_city }}, {{ selected.clinic.clinic_county }}
                    <br>
                    {{ selected.clinic.clinic_address }}
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
        <hr class="mb-4">
        <div v-if="showFunction === 0" class="p-5">
          <h3>Appoint List</h3>
          <b-table
            class="table-base"
            hover
            borderless
            :items="dispatchList"
            :fields="fields"
            :current-page="currentPage"
            :per-page="rowPrePage"
          >
            <template #head(action)>
              <span />
            </template>
            <template #cell(content)="data">
              <div class="d-inline-block text-truncate" style="max-width: 400px;">
                {{ data.item.content }}
              </div>
            </template>
            <template #cell(schedule_date)="data">
              {{ $dayjs(data.item.schedule_date).format('YYYY-MM-DD HH:mm') }}
            </template>
            <template #cell(action)="data">
              <b-link v-if="selected.status===1" class="text-gray-500" href="#" @click.prevent="onDispatchEditClick(data.item)">
                <font-awesome-icon :icon="['fas', 'pen']" fixed-width />
              </b-link>
              <b-link class="text-gray-500 ml-2" href="#" @click.prevent="exportDispatch(data.item.id)">
                <font-awesome-icon :icon="['fas', 'print']" fixed-width />
              </b-link>
            </template>
          </b-table>
          <div class="d-flex justify-content-between mt-6">
            <div />
            <b-pagination
              v-model="currentPage"
              :total-rows="dispatchList.length"
              :per-page="rowPrePage"
              align="fill"
              size="sm"
              class="my-0"
              first-number
              last-number
            />
            <row-count :total-rows="dispatchList.length" :row-pre-page="rowPrePage" :current-page="currentPage" />
          </div>
        </div>
        <b-row v-if="showFunction === 1" class="p-5">
          <b-col cols="12">
            <div v-for="item in requirementList" :key="item.id">
              <div class="d-flex justify-content-between align-items-center mb-3">
                <span class="font-weight-bold">{{ item.publisher.name }}</span>
                <span class="caption">{{ item.published_at }}</span>
              </div>
              <div class="mb-3">
                {{ item.content }}
              </div>
              <hr class="mb-4">
            </div>
          </b-col>
          <b-col v-if="tabIndex !== 2" cols="12">
            <b-form-textarea v-model="requirementContent" />
            <b-button variant="primary" class="btn-width mt-2" @click="createRequirement">
              確認送出
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-col>
    <assign-service-modal ref="assignServiceModal" @update="onDispatchUpdate" />
    <close-service-modal ref="closeServiceModal" @update="onDispatchUpdate" />
    <dispatch-detail-modal ref="dispatchDetailModal" />
  </b-row>
</template>
<script>
import modelList from '@/common/modelList'
import serviceTypeTags from '@/components/common/serviceTypeTags'
import { getServiceList, getServiceDetail, getAttachment } from '@/api/service'
import { getDispatchList, exportDispatch } from '@/api/dispatch'
import { getRequirementList, createRequirement } from '@/api/requirement'
import AssignServiceModal from '@/components/service/assignServiceModal'
import CloseServiceModal from '@/components/service/closeServiceModal'
import RowCount from '@/components/common/rowCount'
import DispatchDetailModal from '@/components/service/dispatchDetailModal'

export default {
  name: 'Service',
  components: {
    serviceTypeTags,
    AssignServiceModal,
    CloseServiceModal,
    RowCount,
    DispatchDetailModal
  },
  data () {
    return {
      tabIndex: 0,
      serviceList: [],

      selected: null,
      dispatchList: [],
      requirementList: [],
      requirementContent: '',

      showFunction: 0, // 0:派工列表, 1: 原廠支援, 2: support, 3: 服務紀錄, 4: 安排歸還
      arrangeSchedule: null,

      currentPage: 1,
      rowPrePage: 5

    }
  },
  computed: {
    dispatchItem () {
      if (this.dispatchList.length > 0) {
        if (this.tabIndex === 2) {
          return this.dispatchList.find(a => a.completed)
        }
        return this.dispatchList[0]
      }
      return null
    },
    fields () {
      return [{ key: 'schedule_date', label: 'Schedule', thStyle: { width: '15%' } },
        { key: 'technician', label: 'Technician', thStyle: { width: '15%' } },
        { key: 'content', label: 'Content', thStyle: { width: '55%' } },
        { key: 'action', label: 'Action', thStyle: { width: '15%' } }]
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      if (this.$route.params.id) {
        this.tabIndex = this.$route.params.tab
        this.getServiceList(this.$route.params.id)
      } else {
        this.getServiceList()
      }
    },
    getServiceList (id = null) {
      getServiceList(this.tabIndex).then(res => {
        this.serviceList = res.data.data
        if (this.serviceList.length > 0) {
          this.getServiceDetail(id === null ? this.serviceList[0].id : id)
        }
      })
    },
    getServiceDetail (id) {
      this.dispatchList = []
      this.requirementList = []
      if (this.tabIndex > 0) this.showFunction = 0
      else this.showFunction = 1

      getServiceDetail(id).then(res => {
        this.selected = res.data.data
        if (this.tabIndex > 0) { this.getDispatchList() } else { this.dispatchList = [] }
        this.getRequirementList()
      })
    },
    getDispatchList () {
      getDispatchList(this.selected.id).then(res => {
        this.dispatchList = res.data.data
      })
    },
    getRequirementList () {
      getRequirementList(this.selected.id).then(res => {
        this.requirementList = res.data.data
      })
    },
    createRequirement () {
      if (this.requirementContent === '') return 0
      const data = {
        service_id: this.selected.id,
        content: this.requirementContent
      }
      createRequirement(data).then(res => {
        this.getRequirementList()
        this.requirementContent = ''
      })
    },
    model (val) {
      return modelList.map(p => p.options).flat().find(a => a.value === val)?.text
    },
    changeTabHandler () {
      this.getServiceList()
    },
    onServiceClick (id) {
      this.getServiceDetail(id)
    },
    onServiceCloseClick () {
      this.$refs.closeServiceModal.show(this.selected.id, this.dispatchList)
    },
    onDispatchEditClick (item) {
      this.$refs.assignServiceModal.show(item.service_id, item)
    },
    async onDispatchUpdate (complete = false) {
      const serviceId = this.selected.id
      if (complete) this.tabIndex = 2
      else if (this.tabIndex === 0) { this.tabIndex = 1 }
      await this.getServiceList(serviceId)
    },
    onDownloadClick (id) {
      getAttachment(id).then((res) => {
        const disposition = res.headers['content-disposition']
        const filename = disposition.substring(disposition.indexOf('filename=') + 9, disposition.length).replace(/"/g, '')
        const fileUrl = URL.createObjectURL(res.data)
        const fileLink = document.createElement('a')

        fileLink.href = fileUrl
        fileLink.download = filename

        fileLink.click()
      })
    },
    exportDispatch (id) {
      exportDispatch(id).then((res) => {
        const disposition = res.headers['content-disposition']
        const filename = disposition.substring(disposition.indexOf('filename=') + 9, disposition.length).replace(/"/g, '')
        const fileUrl = URL.createObjectURL(res.data)
        const fileLink = document.createElement('a')

        fileLink.href = fileUrl
        fileLink.download = filename

        fileLink.click()
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~@/assets/style/variables';

.list{
  height: 530px;
  max-height: 530px;
  overflow-y: auto;
}
.list-item{
  margin: 0;
  border-bottom: $gray-300 solid;
  cursor: pointer;
}
.selected{
  box-shadow: inset -10px -10px 10px 0 rgba(255, 255, 255, 0.7), inset 10px 10px 10px 0 rgba(174, 174, 192, 0.2);
  background-color: #f0f0f5;
  border-radius: 12px;
}
.caption{
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(84, 90, 93, 0.7);
}
.clinic-info{
  padding: 16px;
  border-radius: 12px;
  box-shadow: inset -2px -2px 4px 0 rgba(255, 255, 255, 0.7), inset 2px 2px 4px 0 rgba(174, 174, 192, 0.2);
  background-color: #f0f0f5;
}
.unassigned-text{
  font-size: 16px;
  color: rgba(84, 90, 93, 0.4);
}
</style>
