import apiService from '@/common/api.service'

export function getRequirementList (id) {
  return apiService({
    url: '/api/requirement/service',
    method: 'get',
    params: { service_id: id }
  })
}

export function createRequirement (data) {
  return apiService({
    url: '/api/requirement',
    method: 'post',
    data
  })
}
